<script setup>
import { useI18n } from '@/util';
import { useFormulaData } from './utils';

const props = defineProps({
  fields: {
    type: Array,
    required: true,
  },
  editor: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['showOption']);

const { t } = useI18n();
const { formulaFunctions, formulaOperators } = useFormulaData();
const functions = formulaFunctions.map(({ name, description }) => ({
  name,
  description,
  type: 'function',
}));
const operators = formulaOperators.map(({ name, description, operator, icon, syntax }) => ({
  name,
  description,
  operator,
  icon,
  syntax,
  type: 'operator',
}));

function insertChip(item) {
  if (!props.editor) return;

  // Get the end position of the document
  const endPos = props.editor.state.doc.content.size;

  // Insert the chip at the end
  props.editor
    .chain()
    .setTextSelection(endPos)
    .insertContent({
      type: 'formulaChip',
      attrs: {
        type: item.type,
        value: item.name,
        color: item.type === 'field' ? '#E6F0FF' : undefined,
        icon: item.type === 'function' ? 'lsi-formula-function' : item.icon,
      },
    })
    .run();

  // For functions, add parentheses
  if (item.type === 'function') {
    props.editor.chain().insertContent(' ( )').run();
  }
}

function insertOperator(operator) {
  if (!props.editor) return;

  const endPos = props.editor.state.doc.content.size;

  props.editor.chain().setTextSelection(endPos).insertContent(` ${operator.operator} `).run();
  props.editor.view.dispatch(props.editor.state.tr);
}

const hoveredItem = shallowRef(null);

function hoverItem(item) {
  hoveredItem.value = item;
  emit('showOption', item);
}
</script>

<template>
  <div class="flex h-full w-48 flex-col border-x border-separator">
    <div class="flex-grow overflow-y-auto px-4">
      <LscAccordion>
        <LscAccordionItem name="functions" :title="t('Functions')" class="border-none">
          <LscSidebar>
            <LscSidebarItem
              v-for="func in functions"
              :key="func.name"
              prependIcon="lsi-formula-function"
              class="group"
              :active="hoveredItem === func"
              @click="insertChip(func)"
              @mouseenter="hoverItem(func)"
            >
              {{ func.name }}
              <template #append>
                <LscIconButton
                  v-LsdTooltip="t('Add function')"
                  :ariaLabel="t('Add function')"
                  class="invisible group-hover:visible"
                  icon="lsi-add"
                  size="xs"
                  variant="primary"
                />
              </template>
            </LscSidebarItem>
          </LscSidebar>
        </LscAccordionItem>
        <LscAccordionItem name="fields" :title="t('Fields')" class="border-none">
          <LscSidebar>
            <LscSidebarItem
              v-for="field in fields"
              :key="field.name"
              :prependIcon="field.icon"
              class="group"
              :active="hoveredItem === field"
              @click="insertChip(field)"
              @mouseenter="hoverItem(field)"
            >
              {{ field.name }}
              <template #append>
                <LscIconButton
                  v-LsdTooltip="t('Add field')"
                  :ariaLabel="t('Add field')"
                  class="invisible group-hover:visible"
                  icon="lsi-add"
                  size="xs"
                  variant="primary"
                />
              </template>
            </LscSidebarItem>
          </LscSidebar>
        </LscAccordionItem>
        <LscAccordionItem name="operators" :title="t('Operators')" class="border-none">
          <LscSidebar>
            <LscSidebarItem
              v-for="operator in operators"
              :key="operator.name"
              :prependIcon="operator.icon"
              class="group"
              :active="hoveredItem === operator"
              @click="insertOperator(operator)"
              @mouseenter="hoverItem(operator)"
            >
              {{ operator.name }}
              <template #append>
                <LscIconButton
                  v-LsdTooltip="t('Add operator')"
                  :ariaLabel="t('Add operator')"
                  class="invisible group-hover:visible"
                  icon="lsi-add"
                  size="xs"
                  variant="primary"
                />
              </template>
            </LscSidebarItem>
          </LscSidebar>
        </LscAccordionItem>
      </LscAccordion>
    </div>

    <div class="flex shrink-0 items-center gap-2 border-t p-4 hover:cursor-pointer" @mouseenter="hoverItem(null)">
      <LscIcon icon="lsi-help" size="sm" class="text-icon-subtle" />
      <span class="text-body-2">{{ t('How to start?') }}</span>
    </div>
  </div>
</template>
