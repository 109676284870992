<script setup>
import { sanitizeUrl } from '@/util';

const props = defineProps({
  /**
   * Used to set the item's active state.
   */
  active: {
    type: Boolean,
    default: false,
  },
  /**
   * Whether the item is draggable or not.
   */
  canDrag: {
    type: Boolean,
    default: false,
  },
  /**
   * Used to set the item's disabled state.
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * Used to set the item's draggable state.
   */
  draggable: {
    type: Boolean,
    default: false,
  },
  /**
   * Used to set the item's level of indentation.
   */
  level: {
    type: Number,
    default: 0,
  },
  /**
   * Used to highlight the bottom of the item.
   */
  showBottomDragIndicator: {
    type: Boolean,
    default: false,
  },
  /**
   * Used to highlight the top of the item.
   */
  showTopDragIndicator: {
    type: Boolean,
    default: false,
  },
  /**
   * Link to a external page. Component will render an anchor tag.
   */
  href: {
    type: String,
    default: undefined,
  },
  /**
   * Any icon, will be rendered before the item's label.
   * @type {PropType<LscIconName>}
   */
  prependIcon: {
    type: String,
    default: undefined,
  },
  /**
   * To link to a route. Component will render a router-link.
   * @type {PropType<import('vue-router').RouteLocationRaw>}
   */
  to: {
    type: String,
    default: undefined,
  },
});

const typeSpecificProps = computed(() => {
  if (props.href) {
    return { is: 'a', href: sanitizeUrl(props.href) };
  }
  if (props.to) {
    return { is: 'RouterLink', to: props.to };
  }
  return { is: 'button', type: 'button', disabled: props.disabled };
});

const widthWithIndentation = {
  0: 'w-full',
  1: 'w-11/12',
  2: 'w-10/12',
  3: 'w-9/12',
  4: 'w-8/12',
  5: 'w-7/12',
  6: 'w-6/12',
  max: 'w-6/12',
};
</script>

<template>
  <Component
    v-bind="mergeProps($attrs, typeSpecificProps)"
    :is="typeSpecificProps.is"
    class="group/LscSidebarItem relative ml-auto flex h-9 items-center gap-2 rounded-md p-2 text-body-1 text-default no-underline aria-selected:bg-surface-default"
    :class="[
      widthWithIndentation[level] || widthWithIndentation.max,
      {
        'before:absolute before:-top-[3px] before:left-0 before:z-10 before:h-0.5 before:w-full before:bg-action-primary-default':
          showTopDragIndicator,
        'after:absolute after:-bottom-[3px] after:left-0 after:z-10 after:h-0.5 after:w-full after:bg-action-primary-default':
          showBottomDragIndicator,
        'cursor-pointer hover:bg-hover': !disabled,
      },
    ]"
    :aria-selected="active"
    :draggable="draggable"
  >
    <div class="flex h-full shrink-0 items-center gap-1">
      <div v-if="canDrag" class="flex w-4 items-center justify-center" :class="{ 'cursor-move': draggable }">
        <LscIcon v-show="draggable" icon="lsi-drag" class="hidden group-hover/LscSidebarItem:block" />
      </div>

      <LscIcon
        v-if="prependIcon"
        :icon="prependIcon"
        class="shrink-0"
        :class="active ? 'text-[color:--lsds-a-color-icon-secondary-default]' : 'text-icon-subtle'"
      />

      <div class="flex items-center gap-1 empty:hidden">
        <slot name="prepend" />
      </div>
    </div>

    <LscOverflowEllipsis class="items-center" :class="{ 'font-semibold text-default': active }">
      <slot />
    </LscOverflowEllipsis>

    <div class="ml-auto flex h-full shrink-0 items-center gap-1 empty:hidden">
      <slot name="append" />
    </div>
  </Component>
</template>
