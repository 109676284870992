import { Extension, Node } from '@tiptap/core';
import { Plugin } from '@tiptap/pm/state';
import { Decoration, DecorationSet } from '@tiptap/pm/view';
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import { useI18n } from '@/util';
import CustomfieldAddOrEditAdvancedFormulaChipNodeWrapper from './CustomfieldAddOrEditAdvancedFormulaChipNodeWrapper.vue';

export function useFormulaData() {
  const { t } = useI18n();

  const formulaFunctions = [
    {
      name: 'AND',
      description: t('Returns TRUE if all arguments evaluate to TRUE, otherwise returns FALSE.'),
    },
    {
      name: 'COUNT',
      description: t('Counts the number of numeric values in a specified range or list of arguments.'),
    },
    {
      name: 'DAY',
      description: t('Extracts the day component (1-31) from a given date value.'),
    },
    {
      name: 'IF',
      description: t('Returns one value if a condition is TRUE and another value if it is FALSE.'),
    },
    {
      name: 'MAX',
      description: t('Returns the largest value from a set of numbers or range.'),
    },
    {
      name: 'MEDIAN',
      description: t('Returns the median (middle value) of a set of numbers.'),
    },
    {
      name: 'NOT',
      description: t('Reverses the logical value of its argument (TRUE becomes FALSE, FALSE becomes TRUE).'),
    },
    {
      name: 'OR',
      description: t('Returns TRUE if any argument evaluates to TRUE, otherwise returns FALSE.'),
    },
    {
      name: 'ROUND',
      description: t('Rounds a number to a certain number of decimal places according to standard rules.'),
    },
    {
      name: 'SUM',
      description: t('Adds all the numbers in a range or list of arguments.'),
    },
  ];

  const formulaOperators = [
    {
      name: t('Add'),
      description: t('Adds two values together.'),
      operator: '+',
      syntax: 'value + value',
      icon: 'lsi-add',
    },
    {
      name: t('Subtract'),
      description: t('Subtracts the second value from the first value.'),
      operator: '-',
      syntax: 'value - value',
      icon: 'lsi-remove',
    },
    {
      name: t('Multiply'),
      description: t('Multiplies two values together.'),
      operator: '*',
      syntax: 'value * value',
      icon: 'lsi-close',
    },
    {
      name: t('Divide'),
      description: t('Divides the first value by the second value.'),
      operator: '/',
      syntax: 'value / value',
      icon: 'lsi-forward-slash',
    },
    {
      name: t('Modulo'),
      description: t('Returns the remainder after dividing the first value by the second value.'),
      operator: '%',
      syntax: 'value % value',
      icon: 'lsi-percentage',
    },
    {
      name: t('Equal'),
      description: t('Returns TRUE if two values are equal, otherwise FALSE.'),
      syntax: 'value == value',
      operator: '==',
      icon: 'lsi-formula-equals',
    },
    {
      name: t('Not equal'),
      description: t('Returns TRUE if two values are not equal, otherwise FALSE.'),
      operator: '!=',
      syntax: 'value != value',
      icon: 'lsi-formula-not-equal',
    },
    {
      name: t('Greater than'),
      description: t('Returns TRUE if the first value is greater than the second value.'),
      operator: '>',
      syntax: 'value > value',
      icon: 'lsi-accordion-collapsed',
    },
    {
      name: t('Less than'),
      description: t('Returns TRUE if the first value is less than the second value.'),
      operator: '<',
      syntax: 'value < value',
      icon: 'lsi-collapse',
    },
    {
      name: t('Greater or equal'),
      description: t('Returns TRUE if the first value is greater than or equal to the second value.'),
      operator: '>=',
      syntax: 'value >= value',
      icon: 'lsi-formula-greater-than',
    },
    {
      name: t('Less or equal'),
      description: t('Returns TRUE if the first value is less than or equal to the second value.'),
      operator: '<=',
      syntax: 'value <= value',
      icon: 'lsi-formula-less-than',
    },
  ];

  return {
    formulaFunctions,
    formulaOperators,
  };
}

/**
 * Parses a formula string into Tiptap JSON structure for editing/copying formula
 * @param {string} formulaString - The string representing a formula (e.g. `"SUM" ("Profit" - "Total cost")`).
 * @param {Object<string, string>} knownColumns - An object mapping column names to their icon (e.g. { 'Profit': 'lsi-time', ... }).
 * @returns {Object|null} A Tiptap JSON object representing the document with chips and text nodes, or null if formulaString is falsy.
 */
export function parseFormulaStringToTiptapJson(formulaString, knownColumns, formulaNames) {
  if (!formulaString) return null;

  const content = [];
  let currentText = '';
  let inQuotes = false;
  let quoteChar = null;
  let quoteStart = 0;

  const chars = [...formulaString];
  chars.forEach((char, i) => {
    if (char === '"' || char === "'") {
      if (inQuotes && char === quoteChar) {
        // End of quoted text
        const quotedText = formulaString.slice(quoteStart, i);
        if (currentText) {
          content.push({
            type: 'text',
            text: currentText,
          });
          currentText = '';
        }

        // Determine if it's a function or column
        const isFunction = formulaNames.map((f) => f.name.toUpperCase()).includes(quotedText.toUpperCase());

        content.push({
          type: 'formulaChip',
          attrs: {
            type: isFunction ? 'function' : 'column',
            value: quotedText,
            icon: isFunction ? 'lsi-customfield-formula' : knownColumns[quotedText],
            color: isFunction ? undefined : '#E6F0FF',
          },
        });
        inQuotes = false;
        quoteChar = null;
      } else if (!inQuotes) {
        if (currentText) {
          content.push({
            type: 'text',
            text: currentText,
          });
          currentText = '';
        }
        quoteStart = i + 1;
        inQuotes = true;
        quoteChar = char;
      }
    } else if (!inQuotes) {
      currentText += char;
    }
  });

  // Add any remaining text after the loop
  if (currentText) {
    content.push({
      type: 'text',
      text: currentText,
    });
  }

  return {
    type: 'doc',
    content: [
      {
        type: 'paragraph',
        content,
      },
    ],
  };
}

export const customfieldAdvancedFormulaChipExtension = Node.create({
  name: 'formulaChip',

  group: 'inline',
  inline: true,
  selectable: true,
  atom: true,

  addAttributes() {
    return {
      type: {
        default: 'column',
      },
      value: {
        default: '',
      },
      color: {
        default: undefined,
      },
      icon: {
        default: undefined,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-type="formula-chip"]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', { ...HTMLAttributes, 'data-type': 'formula-chip' }];
  },

  addNodeView() {
    return VueNodeViewRenderer(CustomfieldAddOrEditAdvancedFormulaChipNodeWrapper);
  },
});

/**
 * Creates a ProseMirror plugin for to turn operators and parentheses in the formula editor blue
 * @param {Object} options - Configuration options
 * @param {Array} options.formulaOperators - Array of operator objects with operator and name properties
 * @returns {Plugin} ProseMirror plugin for operator decoration
 */
function createOperatorDecorationPlugin(options) {
  let decorationSet = DecorationSet.empty;

  return new Plugin({
    props: {
      decorations(state) {
        const { doc } = state;
        const { formulaOperators } = options;

        const allOperators = [...formulaOperators, { operator: '(' }, { operator: ')' }];

        const operatorPattern = allOperators
          .map((op) => {
            return op.operator.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
          })
          .join('|');
        const regex = new RegExp(`(${operatorPattern})`, 'g');

        const decorations = [];
        doc.descendants((node, pos) => {
          if (node.isText && node.text) {
            for (const match of node.text.matchAll(regex)) {
              const start = pos + match.index;
              const end = start + match[0].length;
              decorations.push(
                Decoration.inline(start, end, {
                  class: 'formula-operator',
                  style: 'color: #4461D7 !important;', // TODO: replace hex when we have token
                }),
              );
            }
          }
          return true;
        });

        decorationSet = DecorationSet.create(doc, decorations);
        return decorationSet;
      },
    },
  });
}

export const OperatorDecorationExtension = Extension.create({
  name: 'operatorDecoration',

  addOptions() {
    return {
      formulaOperators: [],
    };
  },

  addProseMirrorPlugins() {
    return [createOperatorDecorationPlugin(this.options)];
  },
});
