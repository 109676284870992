<script setup>
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Text from '@tiptap/extension-text';
import { EditorContent, useEditor } from '@tiptap/vue-3';
import { useI18n } from '@/util';
import {
  customfieldAdvancedFormulaChipExtension,
  OperatorDecorationExtension,
  parseFormulaStringToTiptapJson,
  useFormulaData,
} from './utils';

const props = defineProps({
  existingFormulaString: {
    type: String,
    default: '',
  },
});

const { formulaFunctions, formulaOperators } = useFormulaData();

// TODO: replace with custom report builder fields
const fields = [
  { name: 'Available time', type: 'field', icon: 'lsi-time' },
  { name: 'Billable time', type: 'field', icon: 'lsi-time' },
  { name: 'Logged time', type: 'field', icon: 'lsi-time' },
  { name: 'Non billable time', type: 'field', icon: 'lsi-time' },
  { name: 'Profit', type: 'field', icon: 'lsi-billable' },
  { name: 'Total cost', type: 'field', icon: 'lsi-billable' },
  { name: 'Total profit', type: 'field', icon: 'lsi-billable' },
  { name: 'Unavailable time', type: 'field', icon: 'lsi-time' },
];

const functions = formulaFunctions.map(({ name }) => ({
  name,
  type: 'function',
}));

const { t } = useI18n();

// currentInput needs to be a full Ref to maintain the right reactivity
// eslint-disable-next-line lightspeed/prefer-shallow-ref
const currentInput = ref('');
// menuPosition needs to be a full Ref to maintain the right reactivity
// eslint-disable-next-line lightspeed/prefer-shallow-ref
const menuPosition = ref({ pos: 0, node: null });
const showMenu = shallowRef(false);
const allMenuItems = computed(() => [...functions, ...fields]);

const filteredItems = computed(() => {
  if (!currentInput.value) {
    // When clicking a chip, show all items
    return menuPosition.value.node ? allMenuItems.value : [];
  }

  const searchTerm = currentInput.value.toLowerCase();
  return allMenuItems.value.filter((candidate) => candidate.name.toLowerCase().includes(searchTerm));
});

const initialContent = computed(() => {
  if (props.existingFormulaString) {
    return parseFormulaStringToTiptapJson(props.existingFormulaString, fields, formulaFunctions);
  }
  return '';
});

const operatorDecorationExtension = OperatorDecorationExtension.configure({
  formulaOperators: formulaOperators.map((op) => ({
    operator: op.operator,
    name: op.name,
  })),
});

const editor = useEditor({
  extensions: [
    Document,
    Paragraph,
    Text,
    Placeholder.configure({ placeholder: t('Enter manually or choose from the panel on the left') }),
    customfieldAdvancedFormulaChipExtension.configure({
      HTMLAttributes: {
        class: 'formula-chip',
      },
    }),
    operatorDecorationExtension,
  ],
  content: initialContent.value,
  editable: true,
  autofocus: true,
  onUpdate: ({ editor: editorInstance }) => {
    if (menuPosition.value.node) return; // Don't process updates when replacing chips

    const { from } = editorInstance.state.selection;
    const textBefore = editorInstance.state.doc.textBetween(0, from).trim();
    const lastWord = textBefore.split(' ').pop() || '';

    if (lastWord.length > 0) {
      showMenu.value = true;
      currentInput.value = lastWord;
      menuPosition.value = { pos: from - lastWord.length, node: null };
    } else {
      showMenu.value = false;
      currentInput.value = '';
    }
  },
});

defineExpose({
  editor,
  fields,
});

function showAllItemsMenu(event) {
  const { pos, node } = event.detail;
  showMenu.value = true;
  currentInput.value = '';
  menuPosition.value = { pos, node };
}

function chooseMenuItem(selectedItem) {
  if (!editor.value) return;

  if (menuPosition.value.node) {
    // Replace existing chip
    const { pos } = menuPosition.value;
    editor.value
      .chain()
      .setTextSelection(pos)
      .deleteRange({ from: pos, to: pos + 1 }) // Delete the existing chip
      .insertContent({
        type: 'formulaChip',
        attrs: {
          type: selectedItem.type === 'field' ? 'field' : 'function',
          value: selectedItem.name,
          icon: selectedItem.type === 'function' ? 'lsi-customfield-formula' : selectedItem.icon,
        },
      })
      .run();

    // For functions, add parentheses if they don't exist after the position
    if (selectedItem.type === 'function') {
      const textAfter = editor.value.state.doc.textBetween(pos + 1, pos + 3);
      if (textAfter !== '()') {
        editor.value.chain().insertContent(' ( )').run();
      }
    }
  } else {
    // Handle normal insertion
    const { from, to } = editor.value.state.selection;
    const searchText = currentInput.value;
    editor.value
      .chain()
      .deleteRange({ from: from - searchText.length, to })
      .insertContent({
        type: 'formulaChip',
        attrs: {
          type: selectedItem.type === 'field' ? 'field' : 'function',
          value: selectedItem.name,
          icon: selectedItem.type === 'function' ? 'lsi-customfield-formula' : selectedItem.icon,
        },
      })
      .run();

    if (selectedItem.type === 'function') {
      editor.value.chain().insertContent(' ( )').run();
    }
  }

  showMenu.value = false;
  currentInput.value = '';
  menuPosition.value = { pos: 0, node: null };

  // Update cursor position
  nextTick(() => {
    if (editor.value) {
      const pos = editor.value.state.selection.from;
      if (selectedItem.type === 'function') {
        editor.value.commands.setTextSelection(pos - 2); // Position between parentheses
      } else {
        editor.value.commands.setTextSelection(pos);
      }

      // Force a decoration update
      editor.value.view.dispatch(editor.value.state.tr);
    }
  });
}

// Compute formula string from editor content
const formulaString = computed(() => {
  if (!editor.value) return '';

  const json = editor.value.getJSON();
  let formula = '';

  // Process each node in the document
  json.content?.[0]?.content?.forEach((node, index) => {
    if (node.type === 'text') {
      formula += node.text;
    } else if (node.type === 'formulaChip') {
      // Add space before if not at start and previous char isn't a space
      if (index > 0 && !formula.endsWith(' ')) {
        formula += ' ';
      }

      // Add quoted value
      formula += `"${node.attrs.value}"`;

      // Add space after
      if (index < json.content[0].content.length - 1) {
        formula += ' ';
      }
    }
  });

  return formula;
});

// Needed for chip clicks
onMounted(() => {
  const editorElement = editor.value?.view?.dom;
  if (editorElement) {
    editorElement.addEventListener('formula-chip-click', showAllItemsMenu);
  }
});

onUnmounted(() => {
  const editorElement = editor.value?.view?.dom;
  if (editorElement) {
    editorElement.removeEventListener('formula-chip-click', showAllItemsMenu);
  }
  editor.value?.destroy();
});
</script>

<template>
  <div class="relative h-60 max-h-60">
    <div class="rounded-md border-separator bg-surface-default p-3">
      <div class="mb-2 flex items-center gap-2">
        <span class="front-semibold text-body-2 text-subtle">{{ t('Formula') }}</span>
        <LscLabel variant="emphasis" size="sm">{{ t('Beta') }}</LscLabel>
      </div>
      <EditorContent :editor="editor" class="formula-editor prose prose-sm bg-default" />
    </div>

    <LscMenu v-model="showMenu" offset="-30">
      <template #activator="activator">
        <span class="invisible block h-7 whitespace-pre" v-bind="activator.props">{{ currentInput }}</span>
      </template>
      <LscSheet class="w-fit !p-0.5">
        <LscOptionsMenuItem
          v-for="item in filteredItems"
          :key="item.name"
          :text="item.name"
          :prependIcon="item.type === 'function' ? 'lsi-customfield-formula' : item.icon"
          size="lg"
          @click="chooseMenuItem(item)"
        />
      </LscSheet>
    </LscMenu>

    <div class="mt-2 text-body-2">Test formula: {{ formulaString }}</div>
  </div>
</template>

<style scoped>
.formula-editor {
  @apply w-full rounded-md border border-separator hover:border-form-hover;
}

.formula-editor :deep(.ProseMirror) {
  min-height: 6.25rem;
  padding: 0.5rem;
  outline: none;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.formula-editor :deep(.ProseMirror p) {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.25rem;
  min-height: 1.75rem;
  line-height: 1.75rem;
}

/* Style for text nodes to match chip height */
.formula-editor :deep(.ProseMirror p > *) {
  min-height: 1.75rem;
  display: inline-flex;
  align-items: center;
}

.formula-editor :deep(.formula-chip) {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  white-space: nowrap;
  height: 1.75rem;
  outline: none !important;
  box-shadow: none !important;
}

.formula-editor :deep(.formula-chip:focus) {
  outline: none !important;
  box-shadow: none !important;
}

.formula-editor :deep(.ProseMirror-focused) {
  @apply border-form-active;
}

.formula-editor :deep(.ProseMirror p.is-empty::before) {
  content: attr(data-placeholder);
  @apply text-subtle;
}
</style>
